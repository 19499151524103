import request from '@/utils/request'

export function addInit() {
    return request({
      url: "/api/order/shopify/addInit",
      method: "get"
    })
}

// 查询分页
export function listPage(params) {
    return request({
      url: "/api/order/shopify/listPage",
      params,
      method: "get"
    }) 
  }
  
// 查询初始化
export function PageInit() {
    return request({
      url: "/api/order/shopify/pageInit",
      method: "get"
    })
}

export function detail(id) {
  return request({
      url:`/api/order/shopify/${id}`,
      method:'get'
  });
}



export function setOrderSysTips(data) {
  return request({
    url: "/api/order/shopify/systemMemo",
    data,
    method: "put"
  })
}
//编辑系统备注初始化
export function systemMemoInit(id) {
  return request({
      method:'get',
      url:`/api/order/shopify/systemMemoInit/${id}`,
  });
}
//同步订单
export function ordersyncInit() {
  return request({
      method:'get',
      url:'/api/order/shopify/webhook/',
  });
}

//同步订单确认
export function ordersync(params) {
  return request({
    url: "/api/order/shopify/sync",
    params,
    method: "get"
  }) 
}